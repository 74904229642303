<template>
  <div class="image-manage">
    <el-dialog title="查看图片" :close-on-click-modal="false"  :modal-append-to-body='false' :visible.sync="visible" width="670px" center>
      <div class="imageArea">
        <el-popover placement="right-start" width="300" height="300" trigger="hover" popper-class="popImg" v-for="(item, index) in imgList" :key="index">
          <img :src="'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/'+item.url">
          <span  slot="reference" class="imageItem" @click="enlargeImage(item.url)">
            <img :src="'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/'+item.url" alt="">
          </span>
        </el-popover>
        <div v-if="!imgList.length" class="noData">
          暂无数据
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <big-image ref="bigImg"></big-image>
  </div>
</template>
<script>
import bigImage from '@/views/commonPop/bigImage'
export default {
  components: {
    bigImage
  },
  data () {
    return {
      visible: false,
      imgList: []
    }
  },
  methods: {
    init (id, typeList) {
      this.visible = true
      this.$http({
        url: this.$http.adornUrl('/enclosure/findListByTypeList'),
        method: 'POST',
        data: this.$http.adornParams({
          'businessId': id,
          'businessTypeList': typeList
        })
      }).then(({ data }) => {
        this.imgList = data
      })
    },
    // 放大图片
    enlargeImage (image) {
      this.$refs.bigImg.init(image)
      // this.$alert(`<div class="imgBox"><img src="${image}" alt=""></div>`, '查看大图', {
      //   dangerouslyUseHTMLString: true,
      //   customClass: 'bigImg'
      // })
    }
  }
}
</script>

<style lang="less">
.image-manage {
  .imageArea {
    background: #e6e6e6;
    margin: 0 10px;
    .imageItem {
      display: inline-block;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      cursor: pointer;
      img {
        vertical-align: middle;
        width: 110px;
        height: 110px;
      }
    }
    .el-dialog__wrapper {
      z-index: 1500 !important;
    }
    .noData {
      width: 100%;
      text-align: center;
      height: 100px;
      line-height: 100px;
      font-size: 22px
    }
  }
  .el-message-box.bigImg {
    max-width: 80%;
    .imgBox {
      max-height: 500px;
      overflow: auto
    }
}
  .el-dialog {
    margin-top: 5vh !important
  }
  .el-dialog__body {
    max-height: 450px;
    overflow: auto;
    padding: 10px 25px;
    padding: 0 10px;
  }
  .el-dialog__footer {
    text-align: right
  }
}
.el-message-box.bigImg {
    max-width: 80%;
    .imgBox {
      max-height: 500px;
      overflow: auto
    }
}
.popImg {
  width: 300px;
  height: 300px;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
