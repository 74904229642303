<template>
  <div class="charge-cost-pop common-pop">
    <el-dialog
      title="【查看】费用信息"
      :close-on-click-modal="false"
      width="80%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderInfo.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型">
                <el-select class="item-choose" v-model="dataForm.orderInfo.orderType" :disabled="true"
                           style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车型">
                <el-select class="item-choose" v-model="dataForm.orderInfo.vehicleType" :disabled="true"
                           style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-input v-model="dataForm.orderInfo.status" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.orderInfo.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input v-model="dataForm.orderInfo.customerName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="chargeType">
              <el-form-item label="客户业务员">
                <el-input v-model="dataForm.orderInfo.customerBusinessName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.orderInfo.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50" v-for="(item) in dataForm.orderInfo.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号" prop="customerId">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="件数">
                <el-input v-model="dataForm.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                <el-input v-model="dataForm.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                <el-input v-model="dataForm.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户单号">
                <el-input v-model="dataForm.orderInfo.customerSelfNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="客户联系人">
                <el-input v-model="dataForm.orderInfo.customerContactName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户联系电话">
                <el-input v-model="dataForm.orderInfo.customerContactPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单时间">
                <el-input v-model="dataForm.orderInfo.commitTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单人">
                <el-input v-model="dataForm.orderInfo.commitName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="订单备注">
                <el-input v-model="dataForm.orderInfo.remark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.orderInfo.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-steps :active="statusActive" align-center finish-status="success">
              <el-step title="派车时间" :description="dispatchTime"></el-step>
              <el-step title="出车时间" :description="outDriveTime"></el-step>
              <el-step title="装货到场时间" :description="arrivedTime"></el-step>
              <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
              <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
              <el-step title="签收时间" :description="successTime"></el-step>
            </el-steps>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>客户费用</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" :rules="rules" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="dataForm.freightFee" :disabled="true" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="dataForm.overtimeFee" :disabled="true" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="dataForm.addFee" :disabled="true" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="dataForm.entranceFee" :disabled="true" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="dataForm.loadFee" :disabled="true" placeholder="装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货费" prop="takeFee">
                <el-input v-model="dataForm.takeFee" :disabled="true" placeholder="提货费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="dataForm.parkingFee" :disabled="true" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="dataForm.highFee" :disabled="true" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="dataForm.weighFee" :disabled="true" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="dataForm.otherFee" :disabled="true" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="dataForm.otherFeeRemark" :disabled="true" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="float: right; font-size: 20px; margin-top: 25px; margin-right: 25px;">
        <div v-if="dataForm.status === 2">
          本订单费用平台【已核对】，应付费用小计:<label style="color: red"> {{ dataForm.totalMoney }} 元</label>
        </div>
        <div v-else>
          本订单费用平台【未核对】，请等待平台核对后再来查看
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="medium">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {},
        pieceNum: null,
        weight: null,
        volume: null,
        vehiclePlan: {}
      },
      areaList: [],
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      imgTypeList: {
        entranceFee: [7050], // 报关/入仓
        highFee: [7051], // 高速费
        weighFee: [7052], // 过磅费
        loadFee: [7053], // 装卸费
        overtimeFee: [7054], // 押夜超时费
        takeFee: [7055], // 提货费
        addFee: [7056], // 加点费
        parkingFee: [7008],
        otherFee: [7057]// 其他费用
      },
      costInfoData: {},
      chargeType: true,
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        entranceFee: this._Valid.canNullinit(['nullOrPrice']),
        highFee: this._Valid.canNullinit(['nullOrPrice']),
        weighFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        takeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  components: {
    viewImage
  },
  methods: {
    clearOldValue () {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
    },
    init (type, todo, row, listType) {
      this.visible = true
      this.todo = todo || 'view'
      this.dataForm = row
      this.costInfoData = JSON.parse(JSON.stringify(row))
      this.chargeType = false
      this.clearOldValue()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/customerOrderCost/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.dataForm.pieceNum = data.orderInfo.orderGoods.pieceNum
        this.dataForm.weight = data.orderInfo.orderGoods.weight
        this.dataForm.volume = data.orderInfo.orderGoods.volume
        let orderStatus = data.orderInfo.status
        this.dataForm.orderInfo.status = this.dictTypeMap.orderStatusMap[orderStatus].name
        this.dataForm.orderInfo.takeGoodsDate = data.orderInfo.takeGoodsDate + ' ' + data.orderInfo.takeGoodsTime
        let recordList = data.orderInfo.recordList
        this.dispatchTime = data.orderInfo.dispatchTime
        if (recordList) {
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            if (record.status === 5) {
              this.outDriveTime = record.operationTime
              this.statusActive = 2
            } else if (record.status === 6) {
              this.arrivedTime = record.operationTime
              this.statusActive = 3
            } else if (record.status === 7) {
              this.loadGoodsTime = record.operationTime
              this.statusActive = 4
            } else if (record.status === 15) {
              this.arrivedGiveTime = record.operationTime
              this.statusActive = 5
            }
          }
        }
        if (orderStatus === 8) {
          this.successTime = data.orderInfo.successTime
          this.statusActive = 6
        }
      })
    },
    // 图片预览
    preview (typeList) {
      this.$refs.viewPhoto.init(this.dataForm.id, typeList)
    }
  }
}
</script>
<style lang="less">
.charge-cost-pop {
  .el-form-item {
    margin-bottom: 0px;
  }

  .el-dialog {
    // margin-top: 20vh !important;
    .el-input__inner {
      height: 26px !important;
    }

    .el-dialog__header {
      padding: 10px 20px 10px;
    }
  }

  .el-dialog__body {
    padding: 0;
    max-height: 650px;
    overflow: auto;
  }

  .el-dialog__footer {
    padding: 10px 20px 10px;

    .el-button {
      height: 32px;
      line-height: 0;
    }
  }

  .modularBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;

    .el-col {
      padding-right: 0 !important;
    }
  }

  .modularBox1 {
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .modularBox2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 40px;
    margin-bottom: 2px;

    .addressBtn {
      width: 100%;
      padding: 9px 0px;
      text-align: center
    }

    .deleteBtn {
      width: 100%;
      height: 40px;
      padding-top: 5px;
      text-align: right;
    }
  }

  .modularBox3 {
    padding: 25px;

    .carBox {
      width: 320px;
      margin-right: 20px;
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      text-align: center;
      cursor: pointer;
    }
  }

  .modularBox4 {
    padding: 25px;
  }

  .modularLabel {
    margin-bottom: 5px;
    padding-left: 20px;
    margin-top: 0px;

    span.separate {
      display: inline-block;
      width: 5px;
      margin-right: 10px;
      background: #17B3A3;
      color: transparent;
      height: 20px;
      line-height: 20px;
    }

    label {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .modularLabel2 {
    margin-top: 10px;
    line-height: 30px;
    height: 30px;

    label {
      min-width: 120px;
      float: left;
    }
  }

  .footer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    text-align: right;
    padding: 25px;

    .explain {
      color: #17B3A3 !important
    }
  }
}
</style>
